<template>
  <!-- <div class="py-8 pb-12 mb-12">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'invoices' }">
          Invoices
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mr-1"
            icon
            @click="exportCustomerInvoice(invoice)"
            v-on="on"
          >
            <v-icon>{{ icons.export }}</v-icon>
          </v-btn>
        </template>
        <span>Export to PDF</span>
      </v-tooltip>
    </v-app-bar> -->

  <div class="py-8">
    <div class="px-6">
      <v-app-bar color="transparent" flat>
        <app-bar-nav-icon />
        <v-toolbar-title class="breadcrumbs d-flex align-center">
          <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
          <router-link :to="{ name: 'invoices' }" class="text-decoration--none">
            Back to invoices
          </router-link>
        </v-toolbar-title>
      </v-app-bar>
    </div>

    <div class="px-12 mb-10" v-if="form">
      <v-row>
        <v-col cols="8" class="display-3 font-weight-bold my-6 text-capitalize">
          {{ invoice.tradie_profile.business_name }}
        </v-col>

        <v-col cols="4" class="d-flex align-center justify-end">
          <img
            :src="require('@/assets/images/logo-tradenow.png')"
            alt="tradenow-logo"
            class="pa-0 pr-5"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-0">
          <blue-divider></blue-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-row>
            <div class="title grey--text">
              <strong>INVOICE NUMBER</strong>
            </div>
          </v-row>
          <v-row>
            <p class="subtitle-1">
              <strong>{{ invoice.invoiceNumber }}</strong>
            </p>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <div class="title grey--text"><strong>DATE OF ISSUE</strong></div>
          </v-row>
          <v-row>
            <p class="subtitle-1">
              <strong>{{ invoice.createdAtAttribute }}</strong>
            </p>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" offset="6" class="pa-0">
          <div class="display-1 font-weight-bold text-capitalize">
            {{ invoice.tradie_profile.business_name }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-row>
            <div class="title grey--text"><strong>BILLED TO</strong></div>
          </v-row>
          <v-row>
            <p class="subtitle-1">
              <strong>
                {{ invoice.customer_profile.first_name }}
                {{ invoice.customer_profile.last_name }}
              </strong>
            </p>
          </v-row>
          <v-row class="pt-5">
            <div class="title grey--text">
              <strong>CUSTOMER ADDRESS</strong>
            </div>
          </v-row>
          <v-row>
            <p class="subtitle-1">
              <strong>
                {{ invoice.customer_profile.address }}
              </strong>
            </p>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row>
            <div class="title">
              <strong>QBCC </strong
              ><span class="subtitle-1">{{
                invoice.tradie_profile.license_number
              }}</span>
            </div>
          </v-row>
          <v-row>
            <div class="title">
              <strong>ABN </strong>{{ invoice.tradie_profile.abn }}
            </div>
          </v-row>
          <v-row>
            <div class="title">
              {{ invoice.tradie_profile.address }}
            </div>
          </v-row>
          <v-row class="pt-5">
            <div class="title">
              {{ invoice.tradie_profile.email }}
            </div>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-simple-table>
            <thead>
              <tr class="bb-none">
                <th width="75%" class="title text-left">
                  <strong class="grey--text">DESCRIPTION</strong>
                </th>
                <th width="25%" class="title text-left">
                  <strong class="grey--text">AMOUNT</strong>
                </th>
              </tr>
            </thead>
          </v-simple-table>
          <blue-divider :style="{ borderWidth: '1px' }"></blue-divider>
          <v-simple-table>
            <tbody>
              <tr
                v-for="item in invoice.invoice_items"
                :key="item.invoice_item_id"
              >
                <td class="py-1 px-0">{{ item.name }}</td>
                <td width="25%" class="py-1 px-0">
                  <strong>{{ moneyFormat(item.price) }}</strong>
                </td>
              </tr>
              <tr v-if="loading">
                <td class="pl-0">
                  <v-skeleton-loader type="list-item" />
                </td>
                <td class="pl-0">
                  <v-skeleton-loader type="list-item" />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <blue-divider :style="{ borderWidth: '1px' }"></blue-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-row>
            <div class="title grey--text"><strong>INVOICE TOTAL</strong></div>
          </v-row>
          <v-row>
            <div class="display-3" :style="{ color: 'dodgerblue' }">
              {{ invoice.totalWithGSTAttribute }}
            </div>
          </v-row>
        </v-col>
        <v-col cols="5">
          <v-row>
            <v-col cols="12" class="text-right py-0 pr-6">
              <div class="title">
                <strong>SUBTOTAL </strong>
              </div>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="12" class="text-right pb-0 pr-6">
              <div class="title">
                <strong>GST 10% </strong>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right py-0 pr-6">
              <div class="title">
                <strong>TOTAL </strong>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="12" class="pl-0 pt-0 pb-0">
              <div class="title">
                {{ invoice.subtotalAttribute }}
              </div>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="12" class="pl-0 pb-0">
              <div class="title">
                {{ invoice.gstFee }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pl-0 pt-0 pb-0">
              <div class="title">
                <strong>
                  {{ invoice.totalWithGstAttribute }}
                </strong>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="display-2 font-weight-bold my-6 text-capitalize">
        Tradie Bank Details
      </div>
      <v-row>
        <v-col class="pa-0" cols="12">
          <blue-divider></blue-divider>
          <v-simple-table>
            <thead>
              <tr>
                <th class="title text-center" width="25%">
                  <strong class="black--text">Bank Name</strong>
                </th>
                <th class="title text-center" width="25%">
                  <strong class="black--text">Bank Account Name</strong>
                </th>
                <th class="title text-center" width="25%">
                  <strong class="black--text">Bank Account Number</strong>
                </th>
                <th class="title text-center" width="25%">
                  <strong class="black-text">BSB</strong>
                </th>
              </tr>
            </thead>
          </v-simple-table>
          <v-simple-table>
            <tbody>
              <tr
                v-for="bankAccount in tradieBankDetail"
                :key="bankAccount.bank_account_id"
              >
                <td class="text-center" width="25%">
                  <strong class="subtitle-1">
                    {{ bankAccount.bank_name }}
                  </strong>
                </td>
                <td class="text-center" width="25%">
                  <strong class="subtitle-1">{{
                    bankAccount.bank_account_name
                  }}</strong>
                </td>
                <td class="text-center" width="25%">
                  <strong class="subtitle-1">{{
                    bankAccount.bank_account_number
                  }}</strong>
                </td>
                <td class="text-center" width="25%">
                  <strong class="subtitle-1">{{
                    bankAccount.bank_state_branch
                  }}</strong>
                </td>
              </tr>
              <tr v-if="loading">
                <td class="pl-0">
                  <v-skeleton-loader type="list-item" />
                </td>
                <td class="pl-0">
                  <v-skeleton-loader type="list-item" />
                </td>
                <td class="pl-0">
                  <v-skeleton-loader type="list-item" />
                </td>
                <td class="pl-0">
                  <v-skeleton-loader type="list-item" />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiCheck,
  mdiAlertCircleOutline,
  mdiFilePdf,
} from '@mdi/js'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import BlueDivider from '../../../components/BlueDivider'

import Form from '@/utils/form'

export default {
  name: 'InvoiceDetails',

  components: {
    AppBarNavIcon,
    BlueDivider,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
        export: mdiFilePdf,
      },
      loading: false,
      deleteDialog: false,
      form: null,
      showCropperModal: false,
      tabItems: [{ tab: 'Info' }],
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },

  computed: {
    ...mapState({
      invoice: (state) => state.invoice.invoiceDetails,
      authUser: (state) => state.auth.user,
      payments: (state) => state.payment.list,
    }),
    ...mapGetters('invoice', ['tradieBankDetail']),
  },

  methods: {
    ...mapActions({
      getInvoiceDetails: 'invoice/getInvoiceDetails',
      updateInvoice: 'invoice/updateInvoice',
      deleteInvoice: 'invoice/deleteInvoice',
      exportToPDF: 'invoice/exportInvoice',
    }),

    ...mapMutations({
      clearInvoiceDetails: 'invoice/clearInvoiceDetails',
      clearPayments: 'payment/clearPaymentList',
    }),

    async exportCustomerInvoice(invoice) {
      this.loading = true
      await this.exportToPDF(invoice.invoice_id)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/pdf',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Invoice-${invoice.invoiceNumber}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded', 'success')
        })
        .catch(() => {
          this.showSnackbar('Ops! Something went wrong', 'red')
        })
      this.loading = false
    },

    async getInvoice() {
      this.loading = true
      await this.getInvoiceDetails(this.$route.params.id)

      this.form = new Form(this.invoice)
      this.$store.commit('payment/setPaymentList', this.invoice.payments)
      this.loading = false
    },

    async deleteTradieData() {
      this.form.$busy = true
      this.deleteTradie(this.form.$data()).then(() => {
        this.form.$busy = false
        this.deleteDialog = false
        this.showSnackbar('Invoice deleted successfully!')
        this.$router.replace('/invoices')
      })
    },

    moneyFormat(value, currency = 'USD') {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
      })

      return formatter.format(value)
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
  },

  created() {
    this.getInvoice()
  },

  destroyed() {
    this.clearInvoiceDetails()
    this.clearPayments()
  },

  watch: {
    $route() {
      this.getInvoice()
    },
  },
}
</script>

<style>
.disable-events {
  pointer-events: none;
}

.bb-none td,
.bb-none th {
  border-bottom: none !important;
}
</style>
